import en from '@/locales/en.json';
import da from '@/locales/da.json';

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'da',
    fallbackLocale: 'en',
      messages: {
      en,
      da,
    },
  };
});